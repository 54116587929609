<ng-container *transloco="let t; scope: 'filter'">
  <ts-table-generic
    [config]="{
      type: 'infinite',
      pagingService: productStaffPagingService,
      filterConfig: {
        formFlexibleConfig: filterFormFlexibleConfig,
        formGenericConfig: {
          type: 'button',
          buttonText: t('filter.filter'),
          title: t('filter.productTitle'),
          ignoreScreenSize: true
        }
      }
    }"
  >
    <ng-template #tableHeaderTemplate>
      <th>Judul</th>
      <th>UPC</th>
      <th>Gambar</th>
      <th>Stok</th>
      <th>Harga</th>
      <th>Aksi</th>
    </ng-template>

    <ng-template let-product="value" #tableBodyTemplate>
      <td>
        <div>{{ product.title }}</div>
        <div>{{ product.slug }}</div>
      </td>
      <td>{{ product.upc }}</td>
      <td>
        <ts-product-thumbnail-ui [product]="product"></ts-product-thumbnail-ui>
      </td>
      <td>
        {{ product.inventory_generic.quantity_available }}
      </td>
      <td>
        <ts-product-price-ui
          [product]="product"
          displayType="inline"
        ></ts-product-price-ui>
      </td>
    </ng-template>

    <ng-template let-product="value" #tableActionTemplate>
      <ng-template
        [ngTemplateOutlet]="actionTemplate"
        [ngTemplateOutletContext]="{ value: product }"
      >
      </ng-template>
    </ng-template>
  </ts-table-generic>
</ng-container>

<!-- todo(bambang): refactor -->
