<ts-pagination-infinite-table
  *ngIf="config.type === 'infinite'"
  [pagingService]="config.pagingService"
  [filterConfig]="config.filterConfig"
>
  <tr slot="header">
    <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
  </tr>

  <ng-template let-paging="paging" #pagingTemplate>
    <ng-template
      [ngTemplateOutlet]="content"
      [ngTemplateOutletContext]="{ values: paging.entities }"
    ></ng-template>
  </ng-template>
</ts-pagination-infinite-table>

<table *ngIf="config.type === 'basic'">
  <thead>
    <tr>
      <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    </tr>
  </thead>

  <tbody>
    <ng-template
      [ngTemplateOutlet]="content"
      [ngTemplateOutletContext]="{ values: config.instances }"
    ></ng-template>
  </tbody>
</table>

<ng-template let-values="values" #content>
  <tr *ngFor="let value of values">
    <ng-template
      [ngTemplateOutlet]="bodyTemplate"
      [ngTemplateOutletContext]="{ value: value }"
    >
    </ng-template>
    <td *ngIf="actionTemplate">
      <ng-template
        [ngTemplateOutlet]="actionTemplate"
        [ngTemplateOutletContext]="{ value: value }"
      ></ng-template>
    </td>
  </tr>
</ng-template>
