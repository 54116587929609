import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { strict as assert } from 'assert';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import { I18nService } from '@ts/shared/18n/util-core';
import { AlertService } from '@ts/shared/alert/util-core';
import {
  ApiConsumerCallParams,
  ApiConsumerCallParamsWithBody,
  ApiConsumerCallParamsWithoutBody,
  ApiConsumerService,
  HttpOptions,
} from '@ts/shared/api/data-access-api-consumer';
import {
  BORMA_DAGO_ERROR_CODE_FORM_INVALID,
  BormaDagoError,
  BormaDagoHttpErrorResponse,
} from '@ts/shared/api/util-borma-dago';
import { EnvironmentService } from '@ts/shared/util-environment';
import { logger, LogLevel } from '@ts/shared/util-logging';

@Injectable({
  providedIn: 'root',
})
export class BormaDagoApiConsumerService implements ApiConsumerService {
  bormaDagoApiUrl: string;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private i18nService: I18nService,
    environmentService: EnvironmentService,
  ) {
    this.bormaDagoApiUrl = environmentService.get().bormaDagoApiUrl;
  }

  private showError$(
    message: string | Promise<string>,
    error: HttpErrorResponse,
    logLevel: LogLevel,
    isSilent: boolean,
  ): Observable<never> {
    logger[logLevel](
      `Attempting to open "${error.url}", but received ${error.status}: ${error.message}`,
    );
    if (!isSilent) {
      this.alertService.error$({
        message,
      });
    }
    return EMPTY;
  }

  private showFormatError$(
    errorTranslationKey: string,
    error: HttpErrorResponse,
    logLevel: LogLevel,
    isSilent: boolean,
  ): Observable<never> {
    this.showError$(
      // we don't want to call translation API if isSilent is true, so we
      // implement this "hack".
      isSilent
        ? ''
        : this.i18nService.translate$(
            `sharedApiDataAccessBormaDagoApiConsumer.${errorTranslationKey}`,
            {
              message: error.message,
              url: error.url,
            },
          ),
      error,
      logLevel,
      isSilent,
    );
    return EMPTY;
  }

  private handleErrorFactory({
    errorCodesToHandleManually,
    isSilent,
  }: Pick<
    Required<ApiConsumerCallParams>,
    'errorCodesToHandleManually' | 'isSilent'
  >) {
    return (error: HttpErrorResponse) => {
      if (typeof error.error === 'string') {
        if (
          error.error ===
          '<head/><body>\nMaaf, halaman yang anda minta tidak ada.\n</body>\n'
        ) {
          // Page not found in server for some reason.
          return this.showFormatError$('pageNotFound', error, 'warn', isSilent);
        } else {
          // unknown error
          return this.showFormatError$('unknown', error, 'error', isSilent);
        }
      } else if (!error.error) {
        // unknown error
        return this.showFormatError$('unknown', error, 'error', isSilent);
      } else {
        const error_object = error.error as BormaDagoError;
        const error_code = error_object.error_code;
        const error_detail = error_object.detail;
        if (error_code && errorCodesToHandleManually.indexOf(error_code) > -1) {
          return throwError(() => error as BormaDagoHttpErrorResponse);
        } else if (error_detail) {
          let error_detail_parsed: string;
          if (typeof error_detail === 'string') {
            error_detail_parsed = error_detail;
          } else {
            error_detail_parsed = JSON.stringify(error_detail);
          }
          return this.showError$(error_detail_parsed, error, 'warn', isSilent);
        } else if (error.status === 404) {
          // object not found
          return this.showFormatError$(
            'entityNotFound',
            error,
            'error',
            isSilent,
          );
        } else {
          // unknown error
          return this.showFormatError$('unknown', error, 'error', isSilent);
        }
      }
    };
  }

  private getHttpOptionsWithLanguage(
    baseHttpOptions?: HttpOptions,
  ): HttpOptions {
    const existingHeaders = baseHttpOptions?.headers || new HttpHeaders();

    return {
      ...baseHttpOptions,
      headers: existingHeaders.append(
        'Accept-Language',
        this.i18nService.getLanguageActive(),
      ),
    };
  }

  private paramsProcess(
    body: Exclude<ApiConsumerCallParams['body'], undefined>,
  ): Exclude<ApiConsumerCallParams['body'], undefined> | FormData {
    // If no file in body, don't convert to FormData.
    if (!Object.values(body).find((content) => content instanceof File)) {
      return body;
    }

    // Otherwise, convert it to FormData
    const formData = new FormData();
    Object.keys(body).forEach((key) => {
      const content = body[key];
      if (content instanceof File) {
        formData.append(key, content, content.name);
      } else if (content) {
        formData.append(key, content.toString());
      }
    });
    return formData;
  }

  call$<T>({
    method,
    relativeUrl,
    errorCodesToHandleManually = [],
    queryParams = {},
    httpOptions,
    body,
    isForwardFormErrors,
    isSilent = false,
  }: ApiConsumerCallParams): Observable<T> {
    const url = new URL(relativeUrl, this.bormaDagoApiUrl);
    for (const [key, value] of Object.entries(queryParams)) {
      url.searchParams.set(key, value);
    }
    const completeUrl = url.href;

    // Need omit to bypass typescript http client method
    const httpOptionsWithLanguage = this.getHttpOptionsWithLanguage(
      httpOptions,
    ) as Omit<HttpOptions, 'responseType'>;

    let result: Observable<T>;
    switch (method) {
      case 'get':
        result = this.http.get<T>(completeUrl, httpOptionsWithLanguage);
        break;
      case 'delete':
        result = this.http.delete<T>(completeUrl, httpOptionsWithLanguage);
        break;
      case 'post':
        assert(body);
        result = this.http.post<T>(
          completeUrl,
          this.paramsProcess(body),
          httpOptionsWithLanguage,
        );
        break;
      case 'put':
        assert(body);
        result = this.http.put<T>(
          completeUrl,
          this.paramsProcess(body),
          httpOptionsWithLanguage,
        );
        break;
      case 'patch':
        assert(body);
        result = this.http.patch<T>(
          completeUrl,
          this.paramsProcess(body),
          httpOptionsWithLanguage,
        );
        break;
    }

    result = result.pipe(
      catchError(
        this.handleErrorFactory({
          errorCodesToHandleManually: [
            ...errorCodesToHandleManually,
            ...(isForwardFormErrors
              ? [BORMA_DAGO_ERROR_CODE_FORM_INVALID]
              : []),
          ],
          isSilent,
        }),
      ),
    );

    return result;
  }

  get$<T>(params: ApiConsumerCallParamsWithoutBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'get' });
  }
  delete$<T>(params: ApiConsumerCallParamsWithoutBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'delete' });
  }
  post$<T>(params: ApiConsumerCallParamsWithBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'post' });
  }
  put$<T>(params: ApiConsumerCallParamsWithBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'put' });
  }
  patch$<T>(params: ApiConsumerCallParamsWithBody): Observable<T> {
    return this.call$<T>({ ...params, method: 'patch' });
  }
}
