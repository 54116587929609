import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import {
  FormFlexibleConfig,
  FormGenericConfig,
} from '@ts/shared/form/flexible/util-core';
import { ApiEntityPagingAbstractService } from '@ts/shared/pagination/data-access-entity';

interface FilterConfig<U, V extends Object> {
  formFlexibleConfig: FormFlexibleConfig<U, V>;
  formGenericConfig: FormGenericConfig;
}

export type TableType = 'basic' | 'infinite';

interface TableBasicConfigBase<T> {
  type: TableType & 'basic';
  instances: T[];
}

export type TableBasicConfig<T> = Readonly<TableBasicConfigBase<T>>;

interface TableInfiniteConfigBase<T, U, V extends Object> {
  type: TableType & 'infinite';
  pagingService: ApiEntityPagingAbstractService<T>;
  filterConfig?: FilterConfig<U, V>;
}

export type TableInfiniteConfig<T, U, V extends Object> = Readonly<
  TableInfiniteConfigBase<T, U, V>
>;

export type TableGenericConfig<T, U, V extends Object> =
  | TableBasicConfig<T>
  | TableInfiniteConfig<T, U, V>;

@Component({
  selector: 'ts-table-generic',
  templateUrl: './table-generic.component.html',
  styleUrls: ['./table-generic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableGenericComponent<T, U, V extends Object> {
  @Input() config!: TableGenericConfig<T, U, V>;

  @ContentChild('tableHeaderTemplate') headerTemplate!: TemplateRef<
    Record<string, T>
  >;
  @ContentChild('tableBodyTemplate') bodyTemplate!: TemplateRef<
    Record<string, T>
  >;
  @ContentChild('tableActionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<T>
  >;
}

// todo(bambang): refactor all tables, only product-staff-list-infinite for now, but it still need to be refactored again
